.ContactForm {
    width: 100%;
    background-color: #F9F9F9;
}

.ContactForm fieldset input {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    max-width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
}

.ContactForm fieldset textarea {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    height: 100px;
    max-width: 100%;
    resize: none;
}

.ContactForm fieldset input:hover,
.ContactForm fieldset textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid rgb(33, 106, 159);
}

fieldset {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%;
}
