:root {
	--left-sidebar-percentage: 30%;
}

.Layout {
	display: flex;
	flex-direction: column;
}

.sidebar {
	position: fixed;
	background: #fafafa;
	height: 100%;
	width: var(--left-sidebar-percentage);
	max-width: var(--left-sidebar-percentage);
}

.modal-close-button {
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 11pt;
    cursor: pointer;
    background-color: transparent;
    padding: 15px;
}

.modal-close-button:hover {
    color: rgb(33, 106, 159);
}

.modal-close-button:active {
    color: rgb(151, 134, 189);
}

.modal-close-button:active {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.content {
	/*position: static;*/
	margin-left: var(--left-sidebar-percentage);
	width: calc(100% - var(--left-sidebar-percentage));

	/* This is for the content sections inside */
	display: flex;
	flex-direction: column;
	overflow: scroll;
}

@media (max-width: 991px) {
	.sidebar {
	    position: static;
	    width: 100%;

      /* I just checked the actual height of this on mobile to prevent the
         div from jumping open when the contents animate in
       */
      min-height: 450px;


    	max-width: 100%;
    }

    .content {
	    margin-left: 0;
	    width: 100%;
    }
}
