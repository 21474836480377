.TeachingExperience {
    padding-top: 30px;
    padding-bottom: 20px;
}

.TeachingEntry {
    color: white;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.TeachingEntry .time-range-column {
    min-width: 120px;
    max-width: 120px;
}

.TeachingEntry .time-range-column .time-range {
    font-style: italic;
    margin: 0;
    margin-right: 25px;
    margin-top: 10px;
}

.TeachingEntry .details-column .activity {
    font-weight: normal;
}

.TeachingEntry .details-column p {
    margin: 10px 0;
}

.TeachingEntry .details-column .details .title {
    font-style: italic;
    font-weight: 600;
    margin: 5px 0;
}
