.image {
	padding-top: 50px;
}

.ThesisCard a {
    font-weight: 500;
    color: black;
    text-decoration: underline;
    transition: all 0.3s ease;
}

.ThesisCard a:hover {
    color: #fafafa;
}

.ThesisCard a:active {
    color: #249998;
}

img.thesis-cover {
    max-height: 400px;
}

.thesis-cover-image {
    position: relative
}

.thesis-cover-title {
    position: absolute;
    top: 45px;
    font-size: 13pt;
    color: white;
    font-family: Helvetica Neue;
    font-weight: bold;
    width: 100%;
}

.thesis-cover-title p {
    margin: 5px 0;
}

.thesis-title {
    margin: 10px;
}

.thesis-summary {
    text-align: justify;
    word-break: break-word;
    padding: 5px 20px;
    margin: 0;
}

.doctorate-info {
    margin: 5px;
    font-style: italic;
    font-weight: 600;
}
