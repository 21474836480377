body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
:root {
  --icon-transition: all 0.5s ease;
  --icon-size: 35px;
  --slide-in-distance: -25px;
}

.About {
  padding: 10px 0px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.name {
  font-family: "Allerta Stencil";
  font-weight: normal;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: slide-right-fade-in ease 0.4s;
          animation: slide-right-fade-in ease 0.4s;
  margin-bottom: 10px;
}

.header .details {
  -webkit-animation: slide-right-fade-in ease 0.6s;
          animation: slide-right-fade-in ease 0.6s;
  margin: 15px;
}

.header {
  flex: 3 1;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buttons {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0;

  -webkit-animation: slide-right-fade-in ease 1s;

          animation: slide-right-fade-in ease 1s;
}

.btn.btn-primary {
  display: block;
  font-size: 12pt;
  cursor: pointer;
  background: #249998;
  text-decoration: none;
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.btn.btn-primary:hover {
  background-color: rgb(33, 106, 159);
  box-shadow: 0px 15px 20px rgb(33, 106, 159, 0.4);
  color: #fff;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

.btn.btn-primary:active {
  background-color: rgb(151, 134, 189);
  box-shadow: 0px 15px 20px rgb(151, 134, 189, 0.4);
}

.link-icons {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: slide-right-fade-in ease 0.8s;
          animation: slide-right-fade-in ease 0.8s;
  margin: 15px;
}

.link-icon {
  margin: 0 10px;
}

.link-icon img {
  max-width: 35px;
  max-width: var(--icon-size);
  height: 35px;
  height: var(--icon-size);

  -webkit-filter: grayscale(100%);

          filter: grayscale(100%);

  -webkit-transition: all 0.5s ease;

  transition: all 0.5s ease;

  -webkit-transition: var(--icon-transition);

  transition: var(--icon-transition);
}

.link-icon:hover img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

.link-icon svg {
  max-width: 35px;
  max-width: var(--icon-size);
  height: 35px;
  height: var(--icon-size);

  fill: black;

  -webkit-transition: all 0.5s ease;

  transition: all 0.5s ease;

  -webkit-transition: var(--icon-transition);

  transition: var(--icon-transition);
}

.link-icon .linkedin:hover {
  fill: #0077b5;
  background: white;
}

.link-icon .researchgate:hover {
  fill: #0cb;
  background: white;
}

.link-icon .github:hover {
  fill: #249998;
}

.logo {
  flex: 3 1;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  -webkit-animation: slide-right-fade-in ease 1.2s;

          animation: slide-right-fade-in ease 1.2s;
}

.logo .my-brain {
  max-height: 130px;
}

@keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(-25px, 0px);
            transform: translate(-25px, 0px);
    -webkit-transform: translate(var(--slide-in-distance), 0px);
            transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(-25px, 0px);
    -webkit-transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

.ContentSection {
	position: relative;
	min-height: 250px;
  padding: 20px;

	display: flex;
	justify-content: center;
}

.data {
	position: absolute;
	top: 3%;
	left: 3%;

	text-align: left;
	font-size: 10pt;
}

.details {
	margin: 5px 0;
}

.AboutCard {
	padding: 20px 40px;
	color: white;

  text-align: left;
}


.SelectedPublications {
    padding-top: 40px;
    padding-bottom: 20px;
}

.SelectedPublications a {
    font-weight: 500;
    color: white;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.SelectedPublications a:hover {
    color: black;
}

.SelectedPublications a:active {
    color: #249998;
}

.PublicationEntry {
    color: white;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.PublicationEntry .time-range-column {
    min-width: 60px;
    max-width: 60px;
}

.PublicationEntry .time-range-column .time-range {
    font-style: italic;
    margin: 0;
    margin-right: 25px;
    margin-top: 10px;
}

.PublicationEntry .details-column .title {
    font-weight: bold;
}

.PublicationEntry .details-column .authors {

}

.PublicationEntry .details-column .journal {
    font-style: italic;
    font-weight: 400;
}

.PublicationEntry .details-column p {
    margin: 5px 0;
}

.PublicationEntry .details-column .details .title {
    font-style: italic;
    font-weight: 600;
    margin: 5px 0;
}

.image {
	padding-top: 50px;
}

.ThesisCard a {
    font-weight: 500;
    color: black;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.ThesisCard a:hover {
    color: #fafafa;
}

.ThesisCard a:active {
    color: #249998;
}

img.thesis-cover {
    max-height: 400px;
}

.thesis-cover-image {
    position: relative
}

.thesis-cover-title {
    position: absolute;
    top: 45px;
    font-size: 13pt;
    color: white;
    font-family: Helvetica Neue;
    font-weight: bold;
    width: 100%;
}

.thesis-cover-title p {
    margin: 5px 0;
}

.thesis-title {
    margin: 10px;
}

.thesis-summary {
    text-align: justify;
    word-break: break-word;
    padding: 5px 20px;
    margin: 0;
}

.doctorate-info {
    margin: 5px;
    font-style: italic;
    font-weight: 600;
}

.AcademicHistory {
    padding-top: 40px;
    padding-bottom: 20px;
}

.HistoryEntry {
    color: black;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.HistoryEntry .time-range-column {
    width: 60px;
}

.HistoryEntry a {
    font-weight: 500;
    color: black;
    text-decoration: underline;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.HistoryEntry a:hover {
    color: #fafafa;
}

.HistoryEntry a:active {
    color: #249998;
}

.HistoryEntry .title-line {
    display: flex;
}

.HistoryEntry .title-line p {
    margin: 5px 10px;
}

.HistoryEntry .time-range-column .time-range {
    font-style: italic;
    margin: 0;
    margin-right: 25px;
    margin-top: 10px;
}

.HistoryEntry .details-column .activity {
    font-weight: bold;
}

.HistoryEntry .details-column p {
    margin: 10px 0;
}

.HistoryEntry .details-column .details .title {
    font-style: italic;
    font-weight: 600;
    margin: 5px 0;
}

.TeachingExperience {
    padding-top: 30px;
    padding-bottom: 20px;
}

.TeachingEntry {
    color: white;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.TeachingEntry .time-range-column {
    min-width: 120px;
    max-width: 120px;
}

.TeachingEntry .time-range-column .time-range {
    font-style: italic;
    margin: 0;
    margin-right: 25px;
    margin-top: 10px;
}

.TeachingEntry .details-column .activity {
    font-weight: normal;
}

.TeachingEntry .details-column p {
    margin: 10px 0;
}

.TeachingEntry .details-column .details .title {
    font-style: italic;
    font-weight: 600;
    margin: 5px 0;
}

.ContactForm {
    width: 100%;
    background-color: #F9F9F9;
}

.ContactForm fieldset input {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    max-width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
}

.ContactForm fieldset textarea {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    height: 100px;
    max-width: 100%;
    resize: none;
}

.ContactForm fieldset input:hover,
.ContactForm fieldset textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid rgb(33, 106, 159);
}

fieldset {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%;
}

:root {
	--left-sidebar-percentage: 30%;
}

.Layout {
	display: flex;
	flex-direction: column;
}

.sidebar {
	position: fixed;
	background: #fafafa;
	height: 100%;
	width: 30%;
	width: var(--left-sidebar-percentage);
	max-width: 30%;
	max-width: var(--left-sidebar-percentage);
}

.modal-close-button {
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 11pt;
    cursor: pointer;
    background-color: transparent;
    padding: 15px;
}

.modal-close-button:hover {
    color: rgb(33, 106, 159);
}

.modal-close-button:active {
    color: rgb(151, 134, 189);
}

.modal-close-button:active {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.content {
	/*position: static;*/
	margin-left: 30%;
	margin-left: var(--left-sidebar-percentage);
	width: calc(100% - 30%);
	width: calc(100% - var(--left-sidebar-percentage));

	/* This is for the content sections inside */
	display: flex;
	flex-direction: column;
	overflow: scroll;
}

@media (max-width: 991px) {
	.sidebar {
	    position: static;
	    width: 100%;

      /* I just checked the actual height of this on mobile to prevent the
         div from jumping open when the contents animate in
       */
      min-height: 450px;


    	max-width: 100%;
    }

    .content {
	    margin-left: 0;
	    width: 100%;
    }
}

