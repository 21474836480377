.AcademicHistory {
    padding-top: 40px;
    padding-bottom: 20px;
}

.HistoryEntry {
    color: black;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.HistoryEntry .time-range-column {
    width: 60px;
}

.HistoryEntry a {
    font-weight: 500;
    color: black;
    text-decoration: underline;
    transition: all 0.3s ease;
}

.HistoryEntry a:hover {
    color: #fafafa;
}

.HistoryEntry a:active {
    color: #249998;
}

.HistoryEntry .title-line {
    display: flex;
}

.HistoryEntry .title-line p {
    margin: 5px 10px;
}

.HistoryEntry .time-range-column .time-range {
    font-style: italic;
    margin: 0;
    margin-right: 25px;
    margin-top: 10px;
}

.HistoryEntry .details-column .activity {
    font-weight: bold;
}

.HistoryEntry .details-column p {
    margin: 10px 0;
}

.HistoryEntry .details-column .details .title {
    font-style: italic;
    font-weight: 600;
    margin: 5px 0;
}
