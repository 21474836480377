.ContentSection {
	position: relative;
	min-height: 250px;
  padding: 20px;

	display: flex;
	justify-content: center;
}

.data {
	position: absolute;
	top: 3%;
	left: 3%;

	text-align: left;
	font-size: 10pt;
}

.details {
	margin: 5px 0;
}
