.SelectedPublications {
    padding-top: 40px;
    padding-bottom: 20px;
}

.SelectedPublications a {
    font-weight: 500;
    color: white;
    text-decoration: underline;
    transition: all 0.3s ease;
}

.SelectedPublications a:hover {
    color: black;
}

.SelectedPublications a:active {
    color: #249998;
}

.PublicationEntry {
    color: white;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.PublicationEntry .time-range-column {
    min-width: 60px;
    max-width: 60px;
}

.PublicationEntry .time-range-column .time-range {
    font-style: italic;
    margin: 0;
    margin-right: 25px;
    margin-top: 10px;
}

.PublicationEntry .details-column .title {
    font-weight: bold;
}

.PublicationEntry .details-column .authors {

}

.PublicationEntry .details-column .journal {
    font-style: italic;
    font-weight: 400;
}

.PublicationEntry .details-column p {
    margin: 5px 0;
}

.PublicationEntry .details-column .details .title {
    font-style: italic;
    font-weight: 600;
    margin: 5px 0;
}
