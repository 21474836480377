:root {
  --icon-transition: all 0.5s ease;
  --icon-size: 35px;
  --slide-in-distance: -25px;
}

.About {
  padding: 10px 0px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.name {
  font-family: "Allerta Stencil";
  font-weight: normal;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  animation: slide-right-fade-in ease 0.4s;
  margin-bottom: 10px;
}

.header .details {
  animation: slide-right-fade-in ease 0.6s;
  margin: 15px;
}

.header {
  flex: 3;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buttons {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0;

  animation: slide-right-fade-in ease 1s;
}

.btn.btn-primary {
  display: block;
  font-size: 12pt;
  cursor: pointer;
  background: #249998;
  text-decoration: none;
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.btn.btn-primary:hover {
  background-color: rgb(33, 106, 159);
  box-shadow: 0px 15px 20px rgb(33, 106, 159, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.btn.btn-primary:active {
  background-color: rgb(151, 134, 189);
  box-shadow: 0px 15px 20px rgb(151, 134, 189, 0.4);
}

.link-icons {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-right-fade-in ease 0.8s;
  margin: 15px;
}

.link-icon {
  margin: 0 10px;
}

.link-icon img {
  max-width: var(--icon-size);
  height: var(--icon-size);

  filter: grayscale(100%);

  transition: var(--icon-transition);
}

.link-icon:hover img {
  filter: grayscale(0%);
}

.link-icon svg {
  max-width: var(--icon-size);
  height: var(--icon-size);

  fill: black;

  transition: var(--icon-transition);
}

.link-icon .linkedin:hover {
  fill: #0077b5;
  background: white;
}

.link-icon .researchgate:hover {
  fill: #0cb;
  background: white;
}

.link-icon .github:hover {
  fill: #249998;
}

.logo {
  flex: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  animation: slide-right-fade-in ease 1.2s;
}

.logo .my-brain {
  max-height: 130px;
}

@keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-right-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(var(--slide-in-distance), 0px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}
